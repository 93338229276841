<template>
	<main>
		<HeaderTab :title="$t('global.titre_manage_categories')" />

	    <div id="content">
	        <div class="container-fluid">
	            <div class="box">
                    <p>{{ $t('filtres.aide_drag_drop_cat') }}</p>
                    <div class="row mb-5">
                        <div class="col-6 pr-0 pr-md-3">
                            <h5>{{ $t('categorie.unselected_categories') }}</h5>
                            <draggable class="list-group list-group-left" :list="all_categories_left" v-bind="dragOptionsAllColumns" group="colonne">
                                <div class="list-group-item  d-md-flex align-items-center " v-for="(category, index) in all_categories_left" :key="index">
                                    {{ category.categorie_libelle }}
                                    <div class="btn btn-secondary ml-auto" @click="select_column(category)">
                                        <font-awesome-icon :icon="['fal', 'long-arrow-right']" />
                                    </div>
                                </div>
                            </draggable>
                        </div>
                        <div class="col-6 pl-0 pl-md-3">
                            <h5>{{ $t('categorie.selected_categories') }}</h5>
                            <draggable class="list-group" :list="all_horses_categories_selected" v-bind="dragOptionsSelectedColumns" group="colonne">
                                <div class="list-group-item d-md-flex align-items-center " v-for="(category, index) in all_horses_categories_selected" :key="index">
                                    <div class="btn btn-secondary ml-auto order-md-last">
                                        <font-awesome-icon :icon="['fal', 'sort-alt']" />
                                    </div><br class="d-md-none"/> {{ category.categorie_libelle }}
                                </div>
                            </draggable>
                        </div>
                    </div>
	            <div class="text-center mt-5">
	                <b-button variant="primary" class="rounded-pill" @click="add_categories()">
	                    <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']" class="ml-1"/>
	                </b-button>
	                <b-button class="rounded-pill ml-1" @click="go_back()">
				        {{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']" class="ml-1"/> 
					</b-button>
	            </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import TableMixin from '@/mixins/Table.js'
    import draggable from 'vuedraggable'
    import Horse from '@/mixins/Horse.js'
    import Common from '@/assets/js/common.js'

	export default {
        name: "HorseCategoriesManagement",
        mixins: [TableMixin, Horse],
        data() {
            return {
                dragOptionsAllColumns: {
                    sort: false
                },
                dragOptionsSelectedColumns: {
                    animation: 200
                },
                horse_ids: [],
                all_horses_categories_selected: [],
                all_categories: [],
                all_categories_left: [],
                processing: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.horse_ids = this.$route.params.horse_ids

                let all_horses_categories = []
                await Common.asyncForEach(this.horse_ids, async (horse_id) => {
                    const categories = await this.getHorseCategories(horse_id)
                    all_horses_categories = all_horses_categories.concat(categories)
                })

                all_horses_categories.forEach(category => {
                    if(this.all_horses_categories_selected.filter(c => c.categorie_id === category.categorie_id).length == 0) {
                        this.all_horses_categories_selected.push(category)
                    }
                })

                let all_categories = await this.getAllCategorie()
                all_categories.forEach(category => {
                    if(this.all_horses_categories_selected.filter(c => c.categorie_id === category.categorie_id).length == 0) {
                        this.all_categories_left.push(category)
                    }
                })
            },
            async add_categories() {
                if(this.processing == false) {
                    this.processing = true
                    await Common.asyncForEach(this.horse_ids, async (horse_id) => {
                        await this.removeAllHorseCategorie(horse_id)
                        .then(async () => {
                            await Common.asyncForEach(this.all_horses_categories_selected, async (category) => {
                                await this.addHorseCategorie(horse_id, category.categorie_id)
                            })
                        })
                    }).then(() => {
                        this.processing = false
                    })

                    this.go_back()
                }
            },
            select_column(category) {
                const index = this.all_categories_left.indexOf(category)
                this.all_categories_left.splice(index, 1)
                this.all_horses_categories_selected.push(category)
            },
            go_back() {
                window.history.back()
            }
        },
        components: {
            draggable,
		    HeaderTab: () => import('@/components/HeaderTab')
        }
	}

</script>